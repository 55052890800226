import * as React from 'react';
import { PageProps } from 'gatsby';
import qs from 'query-string';
import { gql } from '@urql/core';
import { CatalogLayout } from 'components/CatalogLayout';
import { useProductsPageQuery } from 'generated/graphql';
import { ProductCardList, ProductCardListSkeleton } from 'components/ProductCardList';
import { ContentTitle } from 'components/ContentTitle';

const Products = ({ location }: PageProps) => {
  const qp = qs.parse(location.search, { arrayFormat: 'comma' });

  // TODO: переписать этот кусок, выглядит криво
  let ids: number[] = [];
  const pause = !qp.ids || typeof qp.title !== 'string';

  if (qp.ids) {
    if (!Array.isArray(qp.ids)) {
      ids.push(parseInt(qp.ids, 10));
    } else {
      ids = [...new Set(qp.ids)].map((id) => parseInt(id, 10));
    }
  }

  const [res] = useProductsPageQuery({
    variables: { ids },
    pause,
  });

  const title = typeof qp.title === 'string' ? qp.title : '';
  if (res.fetching || !res.data) {
    return (
      <CatalogLayout>
        <ContentTitle title={title} backButton />
        <ProductCardListSkeleton count={ids.length || 5} />
      </CatalogLayout>
    );
  }

  if (res.error) {
    return (
      <CatalogLayout>
        <ContentTitle title={title} backButton />
        <div className="text-red">Произошла ошибка</div>
      </CatalogLayout>
    );
  }

  return (
    <CatalogLayout>
      <ContentTitle title={title} backButton />
      <ProductCardList products={res.data.products} />
    </CatalogLayout>
  );
};

export const query = gql`
  query ProductsPageQuery($ids: [Int!]!) {
    products(ids: $ids) {
      ...ProductCard_product
    }
  }
`;

export default Products;
